import React from 'react';

function Button(props) {

    return (
      <div>
        <button onClick = {props.handleChange}>{props.title}</button>
      </div>
    );
}

export default Button;