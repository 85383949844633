import React from 'react';
import Button from './Button';

function Timer(props) {
    
  return (
    <div>
      <br /><p>{props.i}</p>
      <br/>
      <Button
        handleChange = {props.handleChange}
        title = {'Start'}
      />

    </div>
  );
}

export default Timer;