import React, { useState } from 'react';
import './App.css';
import Timer from './Timer';

function App() {
  const [i, setI] = useState(10); // Начальное состояние для таймера

  function startTimer() {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        setI((prevI) => {
          if (prevI > 0) {
            return prevI - 1;
          } else {
            clearInterval(interval);
            resolve('Отсчет закончен');
            return 0;
          }
        });
      }, 1000);
    });
  }

  async function func() {
    const result = await startTimer();
    console.log(result);
  }

  return (
    <div className="App">
      <span>Таймер на промисах</span>
      <Timer
        i={i}
        handleChange={func}
      />
    </div>
  );
}

export default App;